import 'twin.macro';
import rocketImage from './images/Rocket.svg';
import bundeeImage from './images/BundeeText.svg';

function App() {
    return (
        <div className="bg-gradient-to-b from-primary-3 to-primary-2/50 grow flex flex-col overflow-hidden">
            <div className="mx-auto my-auto p-8 flex flex-col bg-white rounded-xl bg-opacity-60 shadow-2xl">
                <h1 className="brand-font text-7xl md:text-9xl bg-gradient-to-r from-primary-2 to-primary-3 inline-block text-transparent bg-clip-text">
                    Bundee
                </h1>
                {/* <img src={bundeeImage} alt="" className="p-2 m-2" /> */}
                <div className="brand-font text-lg md:text-2xl bg-gradient-to-r from-primary-2 to-primary-3 inline-block text-transparent bg-clip-text mx-auto">
                    Digital Culinary Experience
                </div>
                <div className="border-b-2 border-b-black/5 w-2/3 mx-auto my-2"></div>
                <div className="text-sm md:text-lg mx-auto mt-4 text-black/70">
                    Coming soon...
                </div>
            </div>
            <img
                src={rocketImage}
                alt=""
                className="hidden absolute md:block bottom-0 right-0 object-scale-down w-[30rem]"
            />
        </div>
    );
}

export default App;
